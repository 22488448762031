var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component } from "vue-property-decorator";
import { now } from "@common/date";
import Base from "../Base";
import Fund from "./components/Fund.vue";
let default_1 = class extends Base {
    constructor() {
        super(...arguments);
        this.data = null;
    }
    beforeMount() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                this.data = yield post("/api/Fund");
                this.update = now();
            }
            catch (e) {
                this.data = null;
                throw e;
            }
        });
    }
    withdraw() {
        this.$router.push('/fund/withdraw');
    }
    get withdrawing() {
        if (!this.data)
            return 0;
        return this.data.Books.reduce((v, b) => v + b.Outgoing, 0);
    }
};
default_1 = __decorate([
    Component({ components: { Fund } })
], default_1);
export default default_1;
