var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject } from "vue-property-decorator";
import { now } from "@common/date";
import Base from "@/views/Base";
import FeeMessage from "./components/FeeMessage.vue";
import BankModal from "./components/BankModal.vue";
let default_1 = class default_1 extends Base {
    constructor() {
        super(...arguments);
        this.options = null;
        this.data = null;
        this.commonMode = true;
        this.CommonBankId = null;
    }
    beforeMount() {
        this.load();
    }
    get fee() {
        var _a, _b, _c;
        let ids = (_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.Banks.filter(b => b.IsLeapHigh).map(b => b.Id)) !== null && _b !== void 0 ? _b : [];
        let id = (_c = this.data) === null || _c === void 0 ? void 0 : _c.BankId;
        // 目前手續費 15 元是寫死的（後端也會覆寫掉，所以不怕前端竄改）
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return id && ids.includes(id) ? 0 : 15;
    }
    get commonBank() {
        if (!this.data || !this.options)
            return null;
        let b = this.options.CommonOptions.find(a => a.Id == this.CommonBankId);
        if (!b)
            return null;
        if (b.BankId == this.data.BankId && b.AccountNumber == this.data.AccountNumber)
            return b;
        return null;
    }
    selectCommonBank(id) {
        if (!this.data || !this.options)
            return;
        this.CommonBankId = id;
        let b = this.options.CommonOptions.find(a => a.Id == id);
        this.data.BankId = b.BankId;
        this.data.BankName = b.BankName;
        this.data.AccountNumber = b === null || b === void 0 ? void 0 : b.AccountNumber;
        this.data.AccountName = b === null || b === void 0 ? void 0 : b.AccountName;
        this.data.AddToCommon = false;
    }
    get insufficient() {
        return this.availableAmount - this.fee <= 0;
    }
    get disabled() {
        var _a;
        return this.insufficient || this.commonMode && !((_a = this.options) === null || _a === void 0 ? void 0 : _a.CommonOptions.length);
    }
    get availableAmount() {
        var _a;
        if (!this.data || !this.options)
            return 0;
        if (!this.data.FundBookId)
            return 0;
        let book = this.options.Books.find(b => b.Id == this.data.FundBookId);
        return (_a = book === null || book === void 0 ? void 0 : book.Amount) !== null && _a !== void 0 ? _a : 0;
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                this.options = yield post("/api/Fund/GetWithdrawApplicationOption");
                this.data = {
                    Amount: 0,
                    FundBookId: null,
                    BankId: null,
                    BankName: null,
                    AccountNumber: null,
                    AccountName: null,
                    BankAccountAlias: null,
                    AddToCommon: false,
                    Fee: 0,
                };
                this.commonMode = Boolean(this.options.CommonOptions.length);
                this.update = now();
            }
            catch (e) {
                this.data = null;
                throw e;
            }
        });
    }
    submit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            this.data.Fee = this.fee;
            let total = this.data.Amount + this.data.Fee;
            if (!(yield this.confirm(`提領金額 $${this.data.Amount.toComma()} + 手續費 $${this.data.Fee.toComma()} = $${total.toComma()}`, "將扣除金額", "warning")))
                return;
            yield post("/api/Fund/ApplyWithdraw", Object.assign({}, this.data));
            this.$router.back();
        });
    }
    bank(e) {
        e.preventDefault();
        e.target.blur();
        this.$refs.BankModal.show();
    }
    setBank(b) {
        this.data.BankId = b.Id;
        this.data.BankName = b.Name;
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
default_1 = __decorate([
    Component({ components: { FeeMessage, BankModal } })
], default_1);
export default default_1;
