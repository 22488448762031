var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop } from "vue-property-decorator";
import Modal from "@/components/modal";
let default_1 = class default_1 extends Modal {
    constructor() {
        super(...arguments);
        this.filter = null;
    }
    select(b) {
        this.close();
        this.$emit('change', b);
    }
    get filtered() {
        var _a, _b;
        if (!((_a = this.filter) === null || _a === void 0 ? void 0 : _a.trim()))
            return this.data;
        return (_b = this.data) === null || _b === void 0 ? void 0 : _b.filter(b => this.display(b).includes(this.filter));
    }
    display(b) {
        return b.Code + ' ' + b.Name;
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
