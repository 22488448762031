// enum FundType {
// 	LeapHigh,
// 	LeaPA,
// }
export var WithdrawStatus;
(function (WithdrawStatus) {
    WithdrawStatus[WithdrawStatus["Canceled"] = -2] = "Canceled";
    WithdrawStatus[WithdrawStatus["NotApplicable"] = -1] = "NotApplicable";
    WithdrawStatus[WithdrawStatus["NotWithdrawn"] = 0] = "NotWithdrawn";
    WithdrawStatus[WithdrawStatus["Processing"] = 1] = "Processing";
    WithdrawStatus[WithdrawStatus["Withdrawn"] = 2] = "Withdrawn";
})(WithdrawStatus || (WithdrawStatus = {}));
export var FundRecordType;
(function (FundRecordType) {
    FundRecordType[FundRecordType["Appropriate"] = 0] = "Appropriate";
    FundRecordType[FundRecordType["Withdraw"] = 1] = "Withdraw";
})(FundRecordType || (FundRecordType = {}));
