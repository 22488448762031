var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { now } from "@common/date";
import { Collapse } from "bootstrap";
import { WithdrawStatus } from "@/models/Fund";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.collapse = null;
        this.open = false;
        this.WithdrawStatus = WithdrawStatus;
    }
    mounted() {
        let el = this.$el.querySelector(".collapse");
        this.collapse = new Collapse(el, { toggle: false });
    }
    beforeDestroy() {
        this.collapse.dispose();
    }
    get canCancel() {
        return this.data.CreateTime.date() == now().date() && this.data.Status == WithdrawStatus.NotWithdrawn;
    }
    toggle() {
        this.open = !this.open;
        this.collapse.toggle();
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
