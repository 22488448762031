var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop } from "vue-property-decorator";
import { now, today, toISO } from "@common/date";
import Base from "@/views/Base";
import { FundRecordType } from "@/models/Fund";
import Record from "./components/Record.vue";
let default_1 = class default_1 extends Base {
    constructor() {
        super(...arguments);
        this.start = today();
        this.end = today();
        this.data = null;
        this.fund = null;
    }
    created() {
        post("/api/Fund", { Id: this.id }).then(v => this.fund = v);
    }
    mounted() {
        this.load();
    }
    get withdraw() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.filter(r => r.Type == FundRecordType.Withdraw)) !== null && _b !== void 0 ? _b : [];
    }
    get appropriate() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.filter(r => r.Type == FundRecordType.Appropriate)) !== null && _b !== void 0 ? _b : [];
    }
    sum(records) {
        return records.reduce((a, b) => a + b.Amount, 0);
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                this.data = yield post("/api/Fund/Record", {
                    Start: toISO(this.start),
                    End: toISO(this.end),
                });
                this.update = now();
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
                this.$router.back();
            }
        });
    }
    get title() {
        return "營收帳戶";
        // if(!this.fund) return "";
        // return this.fund.Fund.OwnerName + "的營收帳戶";
    }
    onChange(dates) {
        this.start = dates[0];
        this.end = dates[1];
        this.load();
    }
};
__decorate([
    Prop()
], default_1.prototype, "id", void 0);
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
default_1 = __decorate([
    Component({ components: { Record } })
], default_1);
export default default_1;
